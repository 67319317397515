import React from 'react'
import './about.css'
import about from '../images/about3.jpg'
import founder from '../images/guru1.jpg'
import founder1 from '../images/secondguru.jpg'
const About = () => {
  const stories = [
    {
      img: founder, 
      title: 'Gurunathacharya Pandit',
      description: 'Founder',
      subdescription:'NISM certified, 4+ years in F&O Trading'
    },
    {
      img: founder1,
      title: 'Iranna Hosagoudra',
      description: 'Co-Founder',
      subdescription:'NISM certified 3+ years Exp in F&O Trading'
    },
  ];
  return (
    <>    <section className="about-us">
      <div className="about-container">
        <div className="about-text">
          <h2>About Us</h2>
          <h3>Our Mission</h3>
          <p>
            “DREAM BIG WORK HARD” OUR MAIN GOAL IS TO PROVIDE DIVERSIFICATION AND DEPTH INFO REGARDING FUTURE AND OPTION TRADING.
          </p>
        </div>
        <div className="about-image">
          <img src={about} alt="About Us" />
        </div>
      </div>
    </section>



<section className="success-stories-section">
<h2>Thousands of <span className="highlight">Success</span> Stories</h2>
<div className="stories-container">
  {stories.map((story, index) => (
    <div className="story-card" key={index}>
      <div className="story-image" style={{ backgroundImage: `url(${story.img})` }}></div>
      <div className="story-details">
        <h3>{story.title}</h3>
        <p>{story.description}</p>
        <p>{story.subdescription}</p>
      </div>
    </div>
  ))}
</div>
{/* <div className="cta-container">
  <button className="cta-button">I want my free course</button>
</div> */}
</section>
</>

  )
}

export default About
