import React, { useState } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import logo from '../images/logo1.png'

const Navbar = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };



  return (
    <>
      <header className="app-header">
        <div className="header-container">
          <div className="app-logo">
            <img src={logo} alt='logo' width="200px" height="150px"/>
          </div>
          <nav className="app-nav">
            <li><Link to={'/'}>Home</Link></li>
            <li><Link to={'/about'}>About</Link></li>
            <li><Link to={'/certification'}>Certification</Link></li>
            <li><Link to={'/course'}>Courses</Link></li>
            <li><Link to={'/blogs'}>Blogs</Link></li>
          </nav>
          <div className="hamburger-icon" onClick={toggleDrawer}>
            &#9776;
          </div>
        </div>
      </header>

      <div className={`side-drawer ${isDrawerOpen ? 'open' : ''}`}>
        <div className="close-button" onClick={toggleDrawer}>
          &times;
        </div>
        <ul>
          <li onClick={toggleDrawer}><Link to={'/'}>Home</Link></li>
          <li onClick={toggleDrawer}><Link to={'/about'}>About</Link></li>
          <li onClick={toggleDrawer}><Link to={'/certification'}>Certification</Link></li>
          <li onClick={toggleDrawer}><Link to={'/course'}>Courses</Link></li>
          <li onClick={toggleDrawer}><Link to={'/blogs'}>Blogs</Link></li>
        </ul>
      </div>

      {isDrawerOpen && <div className="backdrop" onClick={toggleDrawer}></div>}
    </>
  );
};

export default Navbar;
