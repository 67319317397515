import React from 'react'
import './blog.css'
import blogImage1 from '../images/blog.jpg';
import blogImage2 from '../images/blog1.jpg';

const Blogs = () => {
  return (
    <>
      <div className="blog-page">
      <h1 className="blog-page-title">Trading Blog</h1>
      <div className="blog-list">
        <div className="blog-item">
          <img src={blogImage1} alt="Blog 1" className="blog-image" />
          <div className="blog-content">
            <h2 className="blog-title">Understanding the Basics of Options Trading</h2>
            <p className="blog-description">
              Options trading offers flexibility and opportunities for both risk management and speculative profit. In this blog, we explore the foundational concepts every trader should know.
            </p>
            <button className="read-more">Read More</button>
          </div>
        </div>
        <div className="blog-item">
          <img src={blogImage2} alt="Blog 2" className="blog-image" />
          <div className="blog-content">
            <h2 className="blog-title">Advanced Strategies for Future Trading</h2>
            <p className="blog-description">
              Delve into the advanced strategies that can enhance your trading performance in the futures market. Learn about hedging, leveraging, and more.
            </p>
            <button className="read-more">Read More</button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Blogs
