import React from "react";
import "./home.css";
import chart from "../../components/images/chart.jpg";
import JoinSection from "../joinsection/JoinSection";
import Videos from "../videos/Video";
import {
  FaClock,
  FaVideo,
  FaDiscord,
  FaUserCheck,
  FaBookOpen,
  FaTelegram,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Home = () => {
  const scrollToTop = () => {
    window.scroll(0,0)
  }
  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="content-wrapper">
            <h1 className="main-title">
              Proven System that has helped{" "}
              <span className="highlight">10,000+ traders</span>
            </h1>
            <Link to={"/course"}>
              <button className="cta-button" onClick={scrollToTop}>Explore Courses</button>
            </Link>
            {/* <div className="features">
              <div className="feature">
                <i className="fas fa-sync-alt feature-icon"></i>
                <p>Replace your 9-5 income</p>
              </div>
              <div className="feature">
                <i className="fas fa-plane-departure feature-icon"></i>
                <p>Work while traveling the world</p>
              </div>
              <div className="feature">
                <i className="fas fa-globe feature-icon"></i>
                <p>Take control of your time</p>
              </div>
            </div> */}
          </div>
          <div className="background-overlay"></div>
        </main>

        <div className="container">
          {/* <div className="section">
            <h1>
              Story of <span className="highlight">HighStrike</span>
            </h1>
            <div className="content">
              <div className="chart">
                <img src={chart} alt="BTC Chart" />
              </div>
              <ul className="story-list">
                <li>Struggles of learning to trade</li>
                <li>
                  Finding freedom and becoming a millionaire by the age of 24
                  through trading
                </li>
                <li>Teaching others how to find freedom</li>
              </ul>
            </div>
          </div> */}
          {/* <div className="section">
            <h2>
              Learn Using Our <span className="highlight">3 Part System</span>{" "}
              that has <span className="highlight">Helped Thousands</span>
            </h2>
          </div> */}
        </div>
      </div>

      <JoinSection scrollToTop={scrollToTop}/>
      <Videos />

      <section className="fifth-section">
        <div className="content-grid">
          <div className="content-card">
            <FaClock className="icon" />
            <h3>Educational Material</h3>
            <p>
              This education will significantly shorten your learning curve when
              it comes to trading. With easy-to-implement strategies, you will
              be a pro in no time.
            </p>
          </div>
          <div className="content-card">
            <FaTelegram className="icon" />
            <h3>Access to Our Telegram Group</h3>
            <p>
              Get free access to our HighStrike Trading School Discord group.
              Join with other traders and have fun learning and making money
              with everyone.
            </p>
          </div>
          <div className="content-card">
            <FaUserCheck className="icon" />
            <h3>Have Our Trading Experts Review Your Trades</h3>
            <p>
              Our trading experts will be able to review your trades and tell
              you exactly what you're doing and help you build a game plan for
              your future trades.
            </p>
          </div>
          <div className="content-card">
            <FaBookOpen className="icon" />
            <h3>All Future and Bonus Lessons</h3>
            <p>
            Join our comprehensive offline trading classes and gain hands-on experience in the world of trading. 
            </p>
          </div>
        </div>
        <div className="cta-button_fifth">
          <Link to={"/course"}>
            <button className="cta-button" onClick={scrollToTop}>Explore Courses</button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Home;
