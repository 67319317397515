import React from "react";
import "./joinsection.css";
import { Link } from "react-router-dom";

const JoinSection = ({scrollToTop}) => {
  return (
    <>
      <section className="landing-section">
        <h2 className="title">
          Achieve the <span className="underline">following</span> when you{" "}
          <span className="underline">join</span>
        </h2>
        <div className="card-container">
          <div className="card">
            <i className="fas fa-sync-alt feature-icon"></i>
            <p>Create the perfect environment for trading success</p>
          </div>
          <div className="card">
          <i className="fas fa-plane-departure feature-icon"></i>
            <p>Safely use options to grow your account exponentially quicker</p>
          </div>
          <div className="card">
          <i className="fas fa-globe feature-icon"></i>
            <p>
              Understand charts & technical analysis to find A+ trade setups
            </p>
          </div>
          <div className="card">
          <i className="fas fa-sync-alt feature-icon"></i>
            <p>Use advanced strategies to maximize your profits</p>
          </div>
        </div>
        <Link to={"/course"}>
            <button className="cta-button" onClick={scrollToTop}>Explore Courses</button>
          </Link>
      </section>
    </>
  );
};

export default JoinSection;
