import React from 'react'
import './certificate.css'
import certificate1 from '../images/certificate.jpg';
import certificate2 from '../images/certificate2.jpg';

const Certificate = () => {
  return (
    <div className="certificates-container">
    <h2 className="certificates-heading">Trading Certificates</h2>
    <p className="certificates-description">
      Our team has achieved significant milestones in trading education. Below are the certifications we have received, showcasing our expertise and commitment to providing quality trading knowledge.
    </p>
    <div className="certificates-gallery">
      <div className="certificate-card">
        <img src={certificate1} alt="Trading Certificate 1" />
      </div>
      <div className="certificate-card">
        <img src={certificate2} alt="Trading Certificate 2" />
      </div>
    </div>
  </div>
  )
}

export default Certificate
