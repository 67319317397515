import React from 'react';
import './footer.css';
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-gradient"></div>
      <div className="footer-content">
        <div className="social-media">
          <a href="https://www.instagram.com/futureandoptionworld9?igsh=dTJlNjgzbGt0MzBl" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="social-icon" />
          </a>
          {/* <a href="https://www.facebook.com/your-facebook" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="social-icon" />
          </a>
          <a href="https://www.twitter.com/your-twitter" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="social-icon" />
          </a> */}
          <a href="https://www.linkedin.com/your-linkedin" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="social-icon" />
          </a>
        </div>
        <div className="contact-info">
          <p>
            SHIRUR PARK, SHIGGON LAYOUT NEAR PF QUARTERS,
            <br />
            VIDYANAGAR HUBLI 580021
          </p>
          <p>Phone: 9482427777, 9900163610, 8088027777</p>
          <p>Email: <a href="mailto:futureandoptionworld@gmail.com">futureandoptionworld@gmail.com</a></p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Future and Option World. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
