import React from 'react'
import './video.css'

const Video = () => {
    const students = [
        {
          videoUrl: 'https://www.youtube.com/embed/example1', // Replace with actual video URL
          name: 'Adelia',
          title: 'Future Integration Associate'
        },
        {
          videoUrl: 'https://www.youtube.com/embed/example2', // Replace with actual video URL
          name: 'Cortney',
          title: 'Senior Mobility Supervisor'
        },
        {
          videoUrl: 'https://www.youtube.com/embed/example3', // Replace with actual video URL
          name: 'Arianna',
          title: 'Lead Assurance Developer'
        },
        {
          videoUrl: 'https://www.youtube.com/embed/example4', // Replace with actual video URL
          name: 'Eulah',
          title: 'Lead Branding Strategist'
        },
        {
          videoUrl: 'https://www.youtube.com/embed/example5', // Replace with actual video URL
          name: 'Cesar',
          title: 'Corporate Communications Developer'
        },
      ];
  return (
    <>
       <section className="student-success-section">
      <h2>
        Videos of <span className="highlight">Stock Marketing</span>
        <br />
        {/* <span className="highlight">9-5 income</span> in trading */}
      </h2>
      <p className="description">
        Stacks is a production-ready library of stackable content blocks built in React Native.
      </p>
      <div className="students-container">
        {/* {students.map((student, index) => (
          <div className="student-card" key={index}>
            <div className="video-container">
              <iframe
                src={student.videoUrl}
                title={`Video by ${student.name}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="student-details">
              <h3>{student.name}</h3>
              <p>{student.title}</p>
            </div>
          </div>
        ))} */}
        <h2>Coming Soon</h2>
      </div>
    </section>
    </>
  )
}

export default Video
