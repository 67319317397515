import React from 'react'
import './course.css'

const Course = () => {
  return (
    <section className="pricing-section">
    <h2>Our Course Pricing</h2>
    <div className="pricing-container">
      <div className="pricing-card">
        <h3>Basic to Advance</h3>
        <p>Suitable for beginners and those looking to understand trading basics.</p>
        <p className="price">₹14,999 + GST</p>
        <p className="billing">One Time</p>
        <ul>
          <li>Comprehensive A to Z course</li>
          <li>30 days duration</li>
          <li>Interactive offline sessions</li>
          <li>Basic and advanced strategies</li>
        </ul>
      </div>
      <div className="pricing-card featured">
        <h3>Future and Options Advance</h3>
        <p>Ideal for those who want to dive deep into advanced trading techniques.</p>
        <p className="price">₹24,999 + GST</p>
        <p className="billing">One Time</p>
        <ul>
          <li>30 days of in-depth training</li>
          <li>Advanced strategies and insights</li>
          <li>Hands-on practical experience</li>
          <li>Expert guidance</li>
        </ul>
      </div>
      <div className="pricing-card">
        <h3>Exclusive Mentorship</h3>
        <p>For those who want personalized mentorship and advanced learning.</p>
        <p className="price">Custom Pricing</p>
        <p className="billing">Contact Us</p>
        <ul>
          <li>Tailored training plan</li>
          <li>One-on-one mentorship</li>
          <li>Advanced strategies and insights</li>
          <li>Ongoing support</li>
        </ul>
      </div>
    </div>
    <div className="contact-info">
      <p>Contact for Courses: <a href="tel:+919482427777">9482427777</a>, <a href="tel:+919900163610">9900163610</a>, <a href="tel:+918088027777">8088027777</a></p>
    </div>
  </section>
  )
}

export default Course
